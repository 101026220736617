<template>
    <div class="content">
            <div class="header">
                <img src="~@/assets/images/authentication/bannerfoot.png" alt="">
            </div>

            <div class="inp">
                <input type="text" name="" id="" placeholder="请输入卡号" v-model="value">
            </div>



        <div style="width:80%;margin:40px auto;">
            <!-- <van-loading type="spinner" /> -->
            <van-button color="#062545" v-if="loading" size="large" round @click="sub()" >立即查询</van-button>
            <van-button color="#062545" v-if="!loading"  size="large" round loading-type="spinner" loading loading-text="查询中...">立即查询</van-button>
        </div>
           
         
        
           
    </div>
</template>
<script >
import {getRealStatus} from "@/untils/distubritionIndex.js";
export default {
    data() {
        return {
            value:'',
            times:null,
            loading:true
        }
    },
    methods: {
        sub(){
            if(this.value.trim() =='') return this.$toast('请输入卡号')
            if (this.times) {
                   clearTimeout(this.times);
           }

           this.loading = false
           this.times = setTimeout(()=>{
            let data = {
                iccid:this.value
            }
        
            getRealStatus({data}).then(res=>{
                this.loading = true
                console.log(res);
                if(res.data.code == 0){
                    this.$toast(res.data.msg)
                }
                if(res.data.code == 1){
                    this.$toast(res.data.msg)
                }
                if(res.data.code == 2){
                    this.$toast(res.data.msg)
                    setTimeout(()=>{
                        window.location = res.data.url
                    },3000)
                }
            })

           },1000)

        }
    },
}


</script>
<style lang="less" scoped>
.content {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    .header {
        position: relative;
        width: 100%;
        min-height: 180px;
        background: url('~@/assets/images/authentication/banner.png') no-repeat center;
        background-size: cover;
        img {
            width: 100%;
            position: absolute;
            bottom: 0;
        }
    }

    .inp {
        margin: 0 auto;
        width: 80%;
        height: 50px;
        // background-color: rgb(27, 124, 92);
        margin-top: 30px;
        input {
            background-color: #f9fbfe;
            width: 100%;
            height: 40px;
            margin: 0 auto;
            padding-left: 10px;
            border-radius: 5px;
            border: 1px solid rgb(196, 196, 196);
        }
    }

    .van-button {

        margin: 0 auto;
    }
}
</style>
